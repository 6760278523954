import HomePage from "./Components/homepage";
import Footer from "./Components/footer";
import Howto from "./Components/Howto";
import NotFound from "./Components/NotFound";
import NotePadArticle from "./Components/NotePadArticle";
import Privacypolicy from "./Components/Privacypolicy";
import Discalimer from "./Components/Disclaimer";
import About from "./Components/About";
import CloudflareSSl from "./Components/CloudFlairSSL";
import ContactUs from "./Components/ContactUs";





import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Outlet,
  useParams,
} from "react-router-dom";


function App() {
 
  return (
    <Router>
      <Routes>
        <Route path="/how"  element={<Howto   />} />
        <Route path="/privacy-policy"  element={<Privacypolicy   />} />
        <Route path="/disclaimer"  element={<Discalimer   />} />
        <Route path="/about-us"  element={<About   />} />
        <Route path="/contact-us"  element={<ContactUs   />} />
        <Route path="/notepad-write-your-notes-online"  element={<NotePadArticle   />} />
        <Route path="/:noteid" element={<HomePage />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/" element={<HomePage />} />    
        <Route path="/how-to-install-cloudflare-ssl-certificates-in-plesk" element={<CloudflareSSl />} />
             
      </Routes>
    </Router>
  );
}
export default App;
