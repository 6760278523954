import logo from "../images/notebook.png";

function Navbar() {

    
  return (
    <>
      <nav
        className="navbar navbar-expand-lg navbar-dark fixed-top"
        style={{ backgroundColor: "#516BEB" }}
      >
        <div className="container-fluid">
          <img className="logo" src={logo} />

          <a className="navbar-brand fw-bold" href="/">
             Notepadely   
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <a className="nav-link active" aria-current="page" href="/">
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/how">
                  How it works?
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/notepad-write-your-notes-online">
                  Online Notepad
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="/how-to-install-cloudflare-ssl-certificates-in-plesk">
                  Install SSL in cloudflare
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
